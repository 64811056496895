.user {
    width: -webkit-fill-available;
    color: var(--text-primary);
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 3rem;
  }
  .user__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
  }
  .user__name {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--text-heading);
  }
  .user__name img {
    border: 1px solid var(--border-focus);
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .delete__button button {
    background-color: var(--color-red);
    cursor: pointer;
  }
  .delete__button button:hover {
    background-color: var(--color-red-2);
  }
  .form {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    gap: 2rem;
    align-items: center;
    margin-bottom: 5rem;
    width: 100%;
  }
  .input {
    width: 30%;
    color: var(--text-heading);
  }
  .submit {
    width: 20%;
  }
  .form__buttons {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 1rem;
    width: 100%;
  }
  .cancel {
    background-color: var(--color-orange);
  }
  .cancel:hover {
    background-color: var(--color-orange-2);
  }
  .form__inner {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
  }
  .options {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-paper);
    /* width: max-content; */
    padding: 1rem;
    border-radius: 10px;
    margin-bottom: 1rem;
  }
  .options__inner {
    width: 100%;
    overflow-x: auto;
    display: flex;
    gap: 0.5rem;
  }
  .table{
    width: 80%;
  }
  .radio__div{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  .radio__div .radio__div{
    display: flex;
    flex-direction: column;
    width: 30%;
    justify-content: center;
    align-items: center;
  }