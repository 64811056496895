.users {
  width: -webkit-fill-available;
  color: var(--text-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 3rem;
}
.loans {
  color: var(--text-primary);
  width: -webkit-fill-available;
  height: 100%;
  padding: 2rem;
  /* overflow-y:scroll ; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.report__header {
  width: 20%;
  height: 15%;
  align-self: flex-end;
}
.table {
  width: 90%;
  height: 80%;
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 50%;
  background-color: var(--background-default);
  border: 2px solid var(--border-focus);
  min-height: 50%;
  color: var(--text-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  border-radius: 10px;
}
.container {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal h2 {
  color: var(--text-heading);
}
.container__main {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  gap: 2rem;
}
.inner_container {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.button {
  width: 40%;
}

.input {
  width: 100%;
}
