.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 40%;
  background-color: var(--background-default);
  border: 2px solid var(--border-focus);
  min-height: 30%;
  color: var(--text-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  border-radius: 10px;
}
.container {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal h2 {
  color: var(--text-heading);
}
.container__main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.inner_container {
  display: flex;
  gap: 1rem;
}
.button {
  width: 40%;
}
.delete {
  background-color: var(--color-red);
}
.delete:hover {
  background-color: var(--color-red-2);
}
.buttons {
  margin-top: 2rem;
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.buttons button {
  width: 30%;
}
