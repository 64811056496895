.card {
  width: 25%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  gap: 2rem;
}
.card h3 {
  font-size: 1.5rem;
  color: var(--text-primary);
  margin: 0;
}
.card h2 {
  font-size: 2rem;
  color: var(--text-heading);
  margin: 0;
}
