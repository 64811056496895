.user {
  width: -webkit-fill-available;
  color: var(--text-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 3rem;
}
.user__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}
.user__name {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--text-heading);
}
.user__name img {
  border: 1px solid var(--border-focus);
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.delete__button button {
  background-color: var(--color-red);
  cursor: pointer;
}
.delete__button button:hover {
  background-color: var(--color-red-2);
}
.form {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  gap: 2rem;
  align-items: center;
  margin-bottom: 5rem;
  width: 100%;
}
.input {
  width: 30%;
  color: var(--text-heading);
}
.submit {
  width: 20%;
}
.form__buttons {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.cancel {
  background-color: var(--color-orange);
}
.cancel:hover {
  background-color: var(--color-orange-2);
}
.form__inner {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
}
.options {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-paper);
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.options__inner {
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 0.5rem;
}
.table {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 2rem;
  gap: 2rem;
}
.card__inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 2rem;
}
.card__inner button {
  width: 30%;
}

.delete {
  background-color: var(--color-red);
}
.delete:hover {
  background-color: var(--color-red-2);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 40%;
  background-color: var(--background-default);
  border: 2px solid var(--border-focus);
  min-height: 30%;
  color: var(--text-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  border-radius: 10px;
}
.container {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal h2 {
  color: var(--text-heading);
}
.container__main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.inner_container {
  display: flex;
  gap: 1rem;
}
.button {
  width: 40%;
}
.delete {
  background-color: var(--color-red);
}
.delete:hover {
  background-color: var(--color-red-2);
}
.buttons {
  margin-top: 2rem;
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.buttons button {
  width: 30%;
}

.container__inner {
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 90%;
}
.hidden__file {
  display: none;
}
.modal__input {
  width: 90%;
}
.specify {
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}
.specify button {
  width: 30%;
}
.extend {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
