.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25rem;
  height: 100vh;
  overflow: hidden;
  padding-top: 2rem;
  background-color: var(--nav-color);
  color: var(--text-primary);
  transition: width 0.3s ease, background-color 0.3s ease, color 0.3s ease; /* Smooth transition for theme changes */
  position: relative;
  top: 0;
}

.sidebar.collapsed {
  width: 8rem;
}

.top__nav {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.collapsed .top__nav {
  justify-content: center;
}
.inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.logoImage {
  width: 70%;
  transition: width 0.3s ease;
}

.sidebar.collapsed .logoImage {
  display: none;
}

.main {
  margin-top: 2rem;
  width: 95%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

.nav__button {
  background-color: var(--background-default);
  outline: none;
  border: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 600;
  gap: 0.5rem;
  padding: 1rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 97%;
}

.nav__button:hover {
  background-color: var(--hover-color) !important;
  color: var(--primary-color) !important
}

.nav__button:not(:last-child) {
  margin-bottom: 1rem;
}

.sidebar.collapsed .nav__button {
  justify-content: center;
}

.sidebar.collapsed .nav__button span {
  display: none;
}

.user__details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 90%;
  margin-top: 3rem;
  background-color: var(--hover-color);
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  border-radius: 10px;
  padding-left: 1rem;
  transition: background-color 0.3s ease;
}

.sidebar.collapsed .user__details {
  background-color: transparent;
  padding-left: inherit;
  justify-content: center;
}

.user__details h3 {
  font-size: 1.5rem;
  margin: 0;
}

.user__icon {
  font-size: 3rem;
}

.user__information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.sidebar.collapsed .user__information {
  display: none;
}

.wallet__balance {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.active {
  background-color: var(--hover-color);
  color: var(--primary-color);
}

.collapseButton {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary-color);
  margin-left: auto;
  transition: color 0.3s ease;
}

.collapseButton:hover {
  color: var(--hover-primary-color);
}
.submenu{
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-bottom: 1rem;
  transition: height 5s ease  ;
  /* background-color: var(--hover-color); */
  align-items: center;
  /* padding: 0.5rem; */
  border-radius: 5px;
  padding: 1rem 0.25rem
}
