.btn {
  padding: 10px 20px;
  background-color: var(--button-primary);
  color: var(--color-white-1);
  border: none;
  border-radius: 6px;
  width: 100%;
  font-size: 1.3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn:hover {
  background-color: var(--button-hover);
}
.btn:disabled {
  background-color: var(--color-grey-5);
  cursor: not-allowed;
}
