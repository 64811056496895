.roles {
  color: var(--text-primary);
  width: -webkit-fill-available;
  height: 100%;
  padding: 2rem;
  /* overflow-y:scroll ; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.table {
  width: 90%;
  height: 80%;
}
