.dashboard {
  color: var(--text-primary);
  width: -webkit-fill-available;
  height: 100%;
  padding: 2rem;
  /* overflow-y:scroll ; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  gap: 2rem;
}
.card__inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.card__inner button {
  width: 30%;
}
.card__inner:first-of-type {
    padding-bottom: 2rem;
 border-bottom: 1px solid var(--border-color);
}
.delete {
  background-color: var(--color-red);
}
.delete:hover {
  background-color: var(--color-red-2);
}
