.apply {
    color: var(--text-primary);
    width: -webkit-fill-available;
    height: 100%;
    padding: 2rem;
    /* overflow-y:scroll ; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }
  .stepper {
    width: 90%;
  }
  .form {
    display: flex;
    width: 90%;
    flex-direction: column;
  }
  .form__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;
  }
  .component {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
  }
  .input {
    width: 30%;
  }
  .buttons {
    display: flex;
    gap: 1rem;
    width: 80%;
    align-self: center;
    justify-content: center;
  }
  .buttons button {
    width: 20%;
  }
  .prev {
    background-color: var(--color-orange);
  }
  .prev:hover {
    background-color: var(--color-orange-2);
  }
  .loading{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 3rem;
  }
  