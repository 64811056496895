/* src/components/navbar.module.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--nav-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: -webkit-fill-available;
  min-width:-webkit-fill-available ;
  width: 100%;
  flex: 1;
  height: 5rem;
  top: 0;
  position: sticky;
}

.logo {
  width: 10rem;
  display: flex;
  align-items: center;
}

.navbar h3 {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--text-heading);
}
.logo img {
  width: 100%;
  height: 60%;
}
.hidden__logo {
  display: none;
}
.options {
  display: flex;
  align-items: center;
  color: var(--text-primary);
  gap: 1rem;
}
.navbar .icon{
  font-size: 2rem;
  color: var(--text-primary);
  cursor: pointer;
}
.navbar .icon:hover{
  color: var(--primary-color);
}