.analytics {
  color: var(--text-primary);
  width: -webkit-fill-available;
  height: 100%;
  padding: 2rem;
  /* overflow-y:scroll ; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.cards {
  width: 90%;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  gap: 3rem;
}
.charts__group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.charts__group__heading {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.charts__group__heading h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color:var(--text-heading)
}

.button{
  margin-bottom: 0.5rem;
  background-color: var(--background-default);
}