body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track); /* For Firefox */

  /* Webkit (Chrome, Safari) */
  scrollbar-width: thin; /* For Chrome, Safari, Opera */
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track); /* For Chrome, Safari, Opera */
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --scrollbar-thumb: #888888; /* Default thumb color */
  --scrollbar-track: #f1f1f1; /* Default track color */
}

/* Dark mode scrollbar colors */
[data-theme="dark"] {
  --scrollbar-thumb: #ccc; /* Dark mode thumb color */
  --scrollbar-track: #333; /* Dark mode track color */
}
::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
}
:root {
  --background-default: #ffffff;
  --background-paper: #f5f5f5;
  --text-primary: #000000;
  --text-secondary: #606060;
  --text-heading: #0062dc;
  --border-color: #707070;
  --hover-color: #e0e0e0;
  --primary-color: #0062dc;
  --button-primary: #0062dc;
  --hover-primary-color: #0062dc;
  --nav-color: #f8f9fa;
  --border-focus: #0062dc;
  --button-hover: #024daa;
  --scrollbar-thumb: #888888; /* Default thumb color */
  --scrollbar-track: #f1f1f1; /* Default track color */
  --color-primary: #0c1d58;
  --color-secondary-1: #0c1d58;
  --color-secondary-2: #f55446;
  --color-secondary-3: #ea6d62;
  --color-secondary-4: #d35644;
  --color-secondary-5: #fc704e;
  --color-black-1: #131313;
  --color-black-2: #262626;
  --color-grey-1: #333333;
  --color-grey-2: #666666;
  --color-grey-3: #636363;
  --color-grey-4: #636466;
  --color-grey-5: #707070;
  --color-grey-6: #c6c6c6;
  --color-grey-7: #e5e1e1;

  --color-white-1: #ffffff;
  --color-white-2: #f6e6e7;
  --color-white-3: #fee6e0;
  --color-white-4: #d4d4d4;
  --color-green-1: #2c7919;
  --color-green-2: #229e04;
  --color-blue-1: #5349ce;
  --color-blue-2: #0064fe;
  --color-red: #da1a2a;
  --color-red-2: #ba0312;
  --color-orange: #e76107;
  --color-orange-2: #d15400;

}

[data-theme="dark"] {
  --background-default: #121212;
  --background-paper: #1d1d1d;
  --text-heading: #fdc211;
  --button-primary: #fdc211;
  --text-primary: #ffffff;
  --text-secondary: #b0b0b0;
  --button-hover: #dfa800;
  --border-color: #707070;
  --border-focus: #fdc211;
  --hover-color: #333333;
  --primary-color: #fdc211;
  --hover-primary-color: #64b5f6;
  --nav-color: #18181b;
  --scrollbar-thumb: #ccc; /* Dark mode thumb color */
  --scrollbar-track: #333; /* Dark mode track color */
  --color-primary: #0c1d58;
  --color-secondary-1: #0c1d58;
  --color-secondary-2: #f55446;
  --color-secondary-3: #ea6d62;
  --color-secondary-4: #d35644;
  --color-secondary-5: #fc704e;
  --color-black-1: #131313;
  --color-black-2: #262626;
  --color-grey-1: #333333;
  --color-grey-2: #666666;
  --color-grey-3: #636363;
  --color-grey-4: #636466;
  --color-grey-5: #707070;
  --color-grey-6: #c6c6c6;
  --color-grey-7: #e5e1e1;

  --color-white-1: #ffffff;
  --color-white-2: #f6e6e7;
  --color-white-3: #fee6e0;
  --color-white-4: #d4d4d4;
  --color-green-1: #2c7919;
  --color-green-2: #229e04;
  --color-blue-1: #5349ce;
  --color-blue-2: #0064fe;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid var(--primary-color); */
  -webkit-text-fill-color: var(--text-primary);
  -webkit-box-shadow: 0 0 0px 1000px var(--background-paper) inset;
  transition: background-color 5000s ease-in-out 0s;
}
