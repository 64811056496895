.login {
  display: flex;
  color: var(--primary-color);
  width: 100%;
  justify-content: center;
  align-items: center;
}
.container {
  width: 30%;
  border: 1px solid var(--border-color);
  background-color: var(--background-paper);
  display: flex;
  padding: 2rem;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;
}
.logo {
  width: 50%;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.form__div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  margin-bottom: 2rem;
}
.form h3 {
  font-size: 2rem;
  font-weight: 600;
}
.form p {
  align-self: center;
  font-size: 1rem;
  color: var(--text-primary);
}
.form span {
  color: var(--text-heading);
  text-decoration: underline;
  cursor: pointer;
}
.form span:hover {
  color: var(--button-hover);
}
.input {
  width: 100%;
  margin-bottom: 1rem !important;
}
.email_sent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.email_sent h3 {
  font-size: 2rem;
  font-weight: 600;
}
.email_sent p {
  color: var(--text-primary);
}
.email_sent button {
  margin-top: 1rem;
}
